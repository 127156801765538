export const ERROR_STATUS = {
  PENDING_FURTHER_REVIEW: 'PendingFurtherReview',
  VERIFYING: 'Verifying',
  APPROVED: 'Approved',
  DECLINED: 'Declined',
  EXPIRED: 'Expired',
  SYSTEM_ERROR: 'Error',
  INELIGIBLE: 'Ineligible',
  PREQUAL_NOT_FOUND: 'PrequalNotFound',
  VEHICLE_NOT_FOUND: 'VehicleNotFound',
};

export const OUT_OF_POLICY_CODE = 'OutOfPolicy';
export const SUCCESS_CODE = 'Success';
export const LENDER_ERROR = 'LenderError';

export const COUNTER_TYPE = {
  'Down payment': 'downPayment',
  'Loan term': 'loanTerm',
  'Amount to finance': 'amountToFinance',
  APR: 'aprExceedsStateReqs',
};

export const CONSUMER_EMAIL_STATUSES = [
  ERROR_STATUS.APPROVED,
  ERROR_STATUS.PENDING_FURTHER_REVIEW,
  ERROR_STATUS.VERIFYING,
];
export const CONSUMER_EMAIL_VALUES = {
  [ERROR_STATUS.APPROVED]: 'Approved Prequalify Email',
  [ERROR_STATUS.SYSTEM_ERROR]: 'System Error Email',
  [ERROR_STATUS.DECLINED]: 'Declined Prequalify Email',
  [ERROR_STATUS.PENDING_FURTHER_REVIEW]: 'Pending Further Review Email',
  [ERROR_STATUS.VERIFYING]: 'Verifying Email',
};

export const WORK_HOURS = {
  monday: { open: '09:00 AM', close: '09:00 PM' },
  tuesday: { open: '09:00 AM', close: '09:00 PM' },
  wednesday: { open: '09:00 AM', close: '09:00 PM' },
  thursday: { open: '09:00 AM', close: '09:00 PM' },
  friday: { open: '09:00 AM', close: '09:00 PM' },
  saturday: { open: '09:00 AM', close: '09:00 PM' },
  sunday: { open: '10:00 AM', close: '08:00 PM' },
};

export const ELIGIBLE_YEAR_AGE = 12;
export const ELIGIBLE_MILEAGE = 150000;

const ALASKA = 'AK';
const HAWAII = 'HI';

export const INELIGIBLE_STATE_CODES = [ALASKA, HAWAII];

export const FINANCING_RESPONSE_TYPES = {
  UPDATE: 'update',
  PREQUALIFY: 'prequalify',
  EXISTING_PREQUALIFY: 'existing_prequalify',
};

export const GO_EXPIRATION_PRE_QUALIFICATION_DAYS = 30;
