export const TERM_LIST = [
  'I am providing written instructions to Capital One under the Fair Credit Reporting Act, authorizing Capital One to obtain information from my personal credit profile from credit reporting agencies to evaluate my eligibility for auto financing pre-qualification. I certify that the information I provide in connection with my pre-qualification request is true, complete, and accurate. I acknowledge this is not an application for credit.',
  `I expressly authorize that the information I provide in connection with my pre-qualification request, together with the vehicle that I've selected, may be shared with Capital One and that Capital One may return my pre-qualification decision and any applicable pre-qualification terms to Edmunds. I agree that Capital One may use and share my information consistent with its <a href="https://www.capitalone.com/privacy/" target="_blank">privacy policy</a> and contact me about my request for pre-qualification and/or other products and services.`,
  `I expressly consent to the monitoring and storing of my interactions with this website for use in providing this pre-qualification service to me and improving and personalizing Edmunds’ services. See <a href="/about/privacy.html" target="_blank">Edmunds’ Privacy Statement</a> for details.`,
  'I agree that Edmunds and Capital One (and their authorized agents) may call or text me (including via autodialer or prerecorded message) at the telephone number that I am providing regarding my request for pre-qualification from Capital One.',
  'I expressly consent to receive by electronic means only, including through the email address provided, communications regarding my request, including notice of Capital One’s decision on my request as well as important account opening information regarding rates, fees and other costs related to my pre-qualification for auto financing. I understand that these electronic disclosures may not be available in any paper or non-electronic form, so if I would like to retain a copy for my records, I should print or download a copy of these disclosures during the pre-qualification process. I understand that to access and retain these electronic disclosures I must have or have access to: (1) an up-to-date computing or mobile device with a connection to the Internet; (2) an up-to-date web browser; (3) software capable of opening documents in PDF format; (4) access to the email address I provided above; and (5) electronic storage to retain records and/or a printer to print them. I understand that I must consent to receive these materials electronically to submit this application. If jointly requesting pre-qualification for auto financing through this website or mobile app, both persons jointly and individually consent to Capital One sharing all information pertaining to their joint request for pre-qualified auto financing to both individuals, and to receive by electronic means only, including through the email address provided, communications regarding their request for pre-qualified auto financing.\n',
];

export const AFTER_TERMS_INFO =
  'To receive final credit terms, the participating dealer will require a credit application that could result in one or more "hard credit inquiries" that will appear in your credit history and may impact your credit score. Your offer terms may differ from your pre-qualification terms based on your vehicle selection, application information, and changes to your credit profile. The dealership may require additional information or documentation in connection with your credit application and purchase. The dealership will provide you with legally-required financing disclosures and a contract to finalize your terms and purchase.';
export const CONSENT_TEXT =
  'Whenever "you" is more than one person with respect to the request for pre-qualification, any consent provided shall be deemed to be joint and several and solidary wherever appropriate. Pre-qualification invalid for persons under 18 (or 19 in Alabama). California Residents: A married applicant may apply for an individual account. Ohio Residents: The Ohio laws against discrimination require that all creditors make credit equally available to all creditworthy customers and that credit reporting agencies maintain separate credit histories on each individual upon request. The Ohio Civil Rights Commission administers compliance with this law. Rhode Island, Vermont, and New York Residents: A consumer report may be ordered in connection with this request or subsequently for purposes of review or collection of the account or other legitimate purposes associated with the account.';
export const LEGAL_COPY =
  '* To receive credit offer terms and to complete your purchase, you must apply for credit with the applicable dealer, which will result in one or more "hard credit inquiries" that will appear in your credit history and may affect your credit score.';

export const INFO_COPY =
  '† The information that will be shared with the dealer includes contact information, preferred terms, and vehicle information.';

export const APPROVED_VEHICLE_NOT_FOUND_COPY =
  'The particular listing you selected is not eligible for displaying your pre-qualified payments, but many others are.';

export const APPROVED_NON_MATCHED_VIN_COPY =
  "Continue shopping on Edmunds and you'll be able to see the monthly payments you're pre-qualified for on eligible vehicles.";

export const FILTER_SWITCHER_INFO =
  "This will only show dealers that work with the lender you're prequalified with, allowing you to see your real monthly payments.";

export const GOT_IT = 'Got It';

export const CARAMEL_LINK_MESSAGE = 'Show me other cars I can buy online';
