import { get, toNumber, isEmpty, groupBy, minBy } from 'lodash';
import dateFormat from 'dateformat';
/* Constants */
import {
  FINANCING_CONTAINER_FIELDS_STORAGE_PATH,
  API_CALL_ID,
  TERMS,
  CONDITION,
  PRE_QUAL_NOT_FOUND_ERROR_REGEX,
  LENDERS,
  EMPLOYMENT_STATUS_API_VALUES,
  CONFIRMATION_DATA,
  REVIEW_CONFIRMATION_FIELDS_MIN_VALUE,
  CAPITAL_ONE_PROVIDER,
  PREQUAL_MSG_APPROVED,
} from 'client/site-modules/financing/constants/financing';
import { CONSENT_TEXT, TERM_LIST } from 'site-modules/shared/constants/financing/texts';
import { VALIDATION_URL } from 'site-modules/shared/constants/lead-form/validation';
import { EdmundsAPI } from 'client/data/api/api-client';
import { BASICS_SCREEN_FLOWS } from 'site-modules/financing/constants/steps';
import {
  BASICS_STEP,
  EMPLOYMENT_STEP,
  ERROR_STEP,
  LOOK_UP_FAILURE_STEP,
  LOOK_UP_STEP,
  PERSONAL_STEP,
  RESIDENCE_STEP,
  REVIEW_STEP,
} from 'site-modules/shared/constants/financing/steps';
import { JOINT } from 'site-modules/shared/constants/financing/financing-constants';
import {
  TOTAL_TRADE_IN_MSG,
  NEGATIVE_TRADE_IN_MSG,
  APPLIED_TRADE_IN_MSG,
} from 'site-modules/shared/constants/calculator/calculator';
import {
  COUNTER_DEFINITIONS,
  NOTIFICATION_MSG,
  NOTIFICATION_MSG_NO_COUNTERS,
  UNIT_DEFINITION,
} from 'client/site-modules/shared/constants/financing/mock-financing-data';
import {
  SUCCESS_CODE,
  GO_EXPIRATION_PRE_QUALIFICATION_DAYS,
  ERROR_STATUS,
} from 'site-modules/shared/constants/financing/financing';
import { INVENTORY_TYPES_LOWERCASE } from 'client/constants/inventory-types';
import {
  CREDIT_PROVIDER,
  PREQUAL_MSG,
  INVENTORY_TYPE,
} from 'client/site-modules/shared/constants/allowed-seo-srp-request-params';

/* Utils */
import { getPathPrefix, createPath } from 'client/site-modules/shared/utils/car-buying/link-util';
import { FinancingStorage } from 'client/site-modules/financing/utils/storage';
import { clearPhone, convertToPhone, createNicePhone } from 'client/site-modules/shared/utils/phone-format';
import { getMetricsHeaders } from 'client/utils/metrics-header';
import { objectToQueryString } from 'site-modules/shared/utils/string';
import { getLeadSubmissionInfo } from 'site-modules/shared/components/lead-form/utils/utils';
import { getAppraisalMMYLabel } from 'site-modules/shared/utils/car-buying/calculator-util';
import { vdpLinkBuilder } from 'site-modules/shared/utils/vdp-link-constructor';
import { getNoVehicleImageToDisplay } from 'site-modules/shared/utils/get-no-vehicle-image-to-display';
import { DEFAULT_WIDTH, getPhotoConfig } from 'site-modules/shared/utils/inventory-image-utils';
import { addDaysToCurrentDate } from 'site-modules/shared/utils/date-utils';
import { getShortcutWorkingHours } from 'site-modules/financing/utils/short-working-hours-mapping';
import { formatPriceString, getCleanValue } from 'site-modules/shared/utils/price-utils';
import { srpLinkBuilder } from 'site-modules/shared/utils/srp-link-constructor';
import { getFinancingPrequalifiedCookie } from 'site-modules/shared/utils/financing/financing-prequalified-cookie';
import { getEncryptedFields, getDecryptedFields } from './encrypting-utils';

export function getVinPath(match) {
  const vin = get(match, 'params.vin');
  return vin ? `vin["${vin}"]` : null;
}

export function getVehicleType(vehicle) {
  return CONDITION[get(vehicle, 'type', '')];
}

export function createLookUpLink(location, activeStep, isPrequalified, isJointState) {
  const path = activeStep === LOOK_UP_STEP ? PERSONAL_STEP : activeStep;
  const pathPrefix = getPathPrefix(location, path);

  const params = { active: isPrequalified ? [LOOK_UP_STEP] : [LOOK_UP_FAILURE_STEP] };

  if (isJointState) params.excluded = [JOINT];
  if (!isPrequalified) params.excluded = [...get(params, 'excluded', []), LOOK_UP_STEP];

  return createPath(pathPrefix, PERSONAL_STEP, location, params);
}

export function getParamsForStep(activeStep, isJointState, isPrev) {
  let params = {};

  if (activeStep === LOOK_UP_STEP) {
    params = { excluded: [LOOK_UP_STEP] };
  } else if (activeStep === BASICS_STEP && isJointState) {
    params = { active: [JOINT] };
  } else if (activeStep === PERSONAL_STEP && isPrev && isJointState) {
    params = { excluded: [JOINT] };
  }

  return params;
}

/**
 * Helper to create a path fo the specific path using current location.
 * @param {object} location
 * @param {string} activeStep  current active step name
 * @param {object} step  current step with necessary data
 * @param {boolean} isPrev
 * @param {boolean} isJointState
 * @param {boolean} isCapOne
 * @param {boolean} isPreVinFlow
 * @returns {string} path
 */

export function createLinkForStep({ location, activeStep, step, isPrev, isJointState, isCapOne, isPreVinFlow }) {
  if ([REVIEW_STEP, LOOK_UP_STEP].includes(activeStep) && isPreVinFlow) {
    return srpLinkBuilder({
      [CREDIT_PROVIDER]: CAPITAL_ONE_PROVIDER,
      [PREQUAL_MSG]: PREQUAL_MSG_APPROVED,
      [INVENTORY_TYPE]: `${INVENTORY_TYPES_LOWERCASE.USED}`,
    });
  }
  const params = getParamsForStep(activeStep, isJointState, isPrev);
  let activeStepPath = activeStep;

  if (activeStep === LOOK_UP_STEP) {
    activeStepPath = get(step, 'path');
  }

  const pathPrefix = getPathPrefix(location, activeStepPath);
  const nextPath = isCapOne ? get(step, 'nextPath') : get(step, 'lastPath', get(step, 'nextPath'));
  const pathToChange = isPrev ? get(step, 'prevPath') : nextPath;
  return createPath(pathPrefix, pathToChange, location, params);
}

export function getFinancialFields() {
  return FinancingStorage.getValueFromSessionStorage(FINANCING_CONTAINER_FIELDS_STORAGE_PATH) || {};
}

export async function setFinancingContainerFields(fields, step) {
  const storageFinancingFields = getFinancialFields();
  const decryptedFields = !isEmpty(storageFinancingFields) ? await getDecryptedFields(storageFinancingFields) : {};
  const encryptedFields = await getEncryptedFields({ ...decryptedFields, [step]: fields });

  FinancingStorage.setValueToSessionStorage(FINANCING_CONTAINER_FIELDS_STORAGE_PATH, encryptedFields);
}

export function getBasicsFields(fields) {
  return {
    fields: [
      { name: 'Financing Type', value: 'Purchase' },
      { name: 'Request Type', value: get(fields, 'requestType', '') },
    ],
    isEditable: false,
  };
}
export function getRequestTypeDefaultValue() {
  return BASICS_SCREEN_FLOWS[0].type;
}
export function getBasicsFieldsWithDefaultValues() {
  return { financingType: 'Purchase', requestType: 'Individual' };
}

export function getPersonalFields(fields) {
  const getFullName = (first, last) => `${get(fields, first, '')} ${get(fields, last, '')}`;
  const getFormattedBirthDate = dateBirth => {
    const birthDate = get(fields, dateBirth, '');
    return `${birthDate.substr(4, 2)}/${birthDate.substr(6, 2)}/${birthDate.substr(0, 4)}`;
  };
  return {
    jointFields: [
      { name: 'Name', value: getFullName('firstName', 'lastName') },
      { name: 'Email Address', value: get(fields, 'email', '') },
      { name: 'Phone Number', value: createNicePhone(get(fields, 'phoneNumber', '')) },
      { name: 'Social Security Number', value: get(fields, 'ssn', '').replace(/\d/g, 'X') },
      { name: 'Date of Birth', value: getFormattedBirthDate('dateBirth') },
      { name: 'Confirm Email Address', value: get(fields, 'confirmEmail', '') },
    ],
    fields: [
      { name: 'Name', value: getFullName('firstNamePrimary', 'lastNamePrimary') },
      { name: 'Email Address', value: get(fields, 'emailPrimary', '') },
      { name: 'Phone Number', value: createNicePhone(get(fields, 'phoneNumberPrimary', '')) },
      { name: 'Social Security Number', value: get(fields, 'ssnPrimary', '').replace(/\d/g, 'X') },
      { name: 'Date of Birth', value: getFormattedBirthDate('dateBirthPrimary') },
      { name: 'Confirm Email Address', value: get(fields, 'confirmEmailPrimary', '') },
    ],
    isEditable: true,
    convert: getPersonalFields,
  };
}

export function getResidenceFields(fields) {
  const getHomeAddress = (aptNum, homeAddress, city, state, zip) =>
    `${get(fields, aptNum, '')} ${get(fields, homeAddress, '')}, ${get(fields, city, '')}, ${get(
      fields,
      state.value,
      ''
    )} ${get(fields, zip, '')}`;
  const getTimeAtResidence = (years, monthsCount) =>
    `${get(fields, years, '')} years, ${get(fields, monthsCount, '')} months`;
  return {
    fields: [
      {
        name: 'Home Address',
        value: getHomeAddress('aptNumPrimary', 'homeAddressPrimary', 'cityPrimary', 'statePrimary', 'zipPrimary'),
      },
      { name: 'Time At Residence', value: getTimeAtResidence('yearsPrimary', 'monthsCountPrimary.value') },
      { name: 'Status', value: get(fields, 'statusPrimary', '') },
      { name: 'Monthly Housing Expense', value: get(fields, 'monthlyRentPrimary', '') },
    ],
    jointFields: [
      { name: 'Home Address', value: getHomeAddress('aptNum', 'homeAddress', 'city', 'state', 'zip') },
      { name: 'Time At Residence', value: getTimeAtResidence('years', 'monthsCount.value') },
      { name: 'Status', value: get(fields, 'status', '') },
      { name: 'Monthly Housing Expense', value: get(fields, 'monthlyRent', '') },
    ],
    isEditable: true,
    convert: getResidenceFields,
  };
}

export function getEmploymentFields(fields) {
  const getDuration = (years, months) => `${get(fields, years, '')} years, ${get(fields, months, '')} months`;
  return {
    jointFields: [
      { name: 'Employment Status', value: get(fields, 'employmentStatus.label', '') },
      { name: 'Name of Employer', value: get(fields, 'employer', '') },
      { name: 'Job Title', value: get(fields, 'jobTitle', '') },
      { name: 'Duration Employed', value: getDuration('years', 'month.value') },
      { name: 'Gross Annual Income', value: get(fields, 'annualIncome', '') },
      { name: 'Additional Annual Income', value: get(fields, 'additionalAnnualIncome', 0) },
    ],
    fields: [
      { name: 'Employment Status', value: get(fields, 'employmentStatusPrimary.label', '') },
      { name: 'Name of Employer', value: get(fields, 'employerPrimary', '') },
      { name: 'Job Title', value: get(fields, 'jobTitlePrimary', '') },
      { name: 'Duration Employed', value: getDuration('yearsPrimary', 'monthPrimary.value') },
      { name: 'Gross Annual Income', value: get(fields, 'annualIncomePrimary', '') },
      { name: 'Additional Annual Income', value: get(fields, 'additionalAnnualIncomePrimary', 0) },
    ],
    isEditable: true,
    convert: getEmploymentFields,
  };
}

export function getDecryptedFinancialFormFields(financingFields) {
  const { basics, personal, residence, employment } = financingFields;

  return {
    Basics: getBasicsFields(basics),
    Personal: getPersonalFields(personal),
    Residence: getResidenceFields(residence),
    Employment: getEmploymentFields(employment),
  };
}
export function getDecryptedFinancialFields() {
  const storageFinancingFields = getFinancialFields();
  return !isEmpty(storageFinancingFields) ? getDecryptedFields(storageFinancingFields) : {};
}
export async function getDecryptedFieldByName(fieldName) {
  const decryptedFields = await getDecryptedFields(getFinancialFields());
  return get(decryptedFields, `${fieldName}`, {});
}

export async function getDecryptedPrequalifyData() {
  const prequalifyData = getFinancingPrequalifiedCookie();
  let data = {};

  if (prequalifyData) {
    data = await getDecryptedFields(JSON.parse(prequalifyData));
  }

  return data;
}

export async function getFinancingContainerFields() {
  const financingDecryptedFields = await getDecryptedFinancialFields();
  const formFields = getDecryptedFinancialFormFields(financingDecryptedFields);

  return {
    formFields,
    financingFields: financingDecryptedFields,
  };
}

export async function postValidateInfo(
  pageName,
  venomVersion,
  isJoint,
  { email, phoneNumber, emailPrimary, phoneNumberPrimary }
) {
  const headers = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      ...{ headers: getMetricsHeaders('validateSubmitedPersonalInfo', pageName, venomVersion) },
    },
    method: 'POST',
  };
  const primaryPayload = {
    ...headers,
    body: objectToQueryString({ email: emailPrimary, phone: phoneNumberPrimary }),
  };
  const jointPayload = {
    ...headers,
    body: objectToQueryString({ email, phone: phoneNumber }),
  };

  try {
    const [
      { emailValid: emailValidPrimary, phoneValid: phoneValidPrimary },
      { emailValid, phoneValid },
    ] = await Promise.all([
      EdmundsAPI.fetchJson(`${VALIDATION_URL}${API_CALL_ID}`, primaryPayload),
      isJoint ? EdmundsAPI.fetchJson(`${VALIDATION_URL}${API_CALL_ID}`, jointPayload) : {},
    ]);

    const isPrimaryValid = emailValidPrimary && phoneValidPrimary;
    const isJointValid = isPrimaryValid && emailValid && phoneValid;
    const primaryValidationInfo = { emailValidPrimary, phoneValidPrimary };
    const jointValidationInfo = { emailValid, phoneValid };
    const validationResult = isJoint
      ? { primary: primaryValidationInfo, joint: jointValidationInfo }
      : { primary: primaryValidationInfo };
    return {
      validationResult,
      formValid: (isJoint && isJointValid) || (!isJoint && isPrimaryValid),
    };
  } catch (e) {
    return {
      isError: true,
      message: e.message,
    };
  }
}

export function getExpenceType(type) {
  const EXPENCE_TYPE_MAP = { Own: 'Mortgage', Rent: 'Rental', Other: 'Others' };

  return EXPENCE_TYPE_MAP[type];
}

export function getPrequalifyData(data) {
  const getFormattedBirthDate = dateBirth =>
    dateBirth ? `${dateBirth.substr(0, 4)}-${dateBirth.substr(4, 2)}-${dateBirth.substr(6, 2)}` : '';
  const isJoint = get(data, 'isJoint') === 'true';

  return {
    applicationType: get(data, 'requestType'),
    applicant: {
      taxId: get(data, 'ssnPrimary'),
      fullName: { firstName: get(data, 'firstNamePrimary'), lastName: get(data, 'lastNamePrimary') },
      dateOfBirth: getFormattedBirthDate(get(data, 'dateBirthPrimary')),
      phoneNumbers: {
        primary: {
          telephoneNumber: convertToPhone(get(data, 'phoneNumberPrimary', '')),
          type: 'Mobile',
        },
      },
      emailAddress: get(data, 'emailPrimary'),
      expenses: [
        {
          monthlyExpenseAmount: toNumber(get(data, 'monthlyRentPrimary', '').replace(/[$,.]/g, '')),
          expenseType: getExpenceType(get(data, 'statusPrimary')),
        },
      ],

      income: {
        annualGrossAmount: toNumber(get(data, 'annualIncomePrimary', '').replace(/[$,.]/g, '')),
        otherIncomes: [
          {
            annualAmount: toNumber(get(data, 'additionalAnnualIncomePrimary', '').replace(/[$,.]/g, '')),
            type: 'Other',
          },
        ],
      },
      addressHistory: {
        currentAddress: {
          address: {
            addressLine1: get(data, 'homeAddressPrimary'),
            city: get(data, 'cityPrimary'),
            stateCode: get(data, 'statePrimary'),
            postalCode: get(data, 'zipPrimary'),
          },
          propertyType: get(data, 'statusPrimary'),
          monthsResiding: toNumber(get(data, 'yearsPrimary[0]', 0)) * 12 + get(data, 'monthsCountPrimary', 0),
        },
      },
      employmentHistory: {
        currentStatus: EMPLOYMENT_STATUS_API_VALUES[get(data, 'employmentStatusPrimary', '')],
        primary: {
          employerName: get(data, 'employerPrimary', ''),
          jobTitle: get(data, 'jobTitlePrimary'),
          monthsEmployed: toNumber(get(data, 'yearsPrimary[1]', 0)) * 12 + get(data, 'monthPrimary', 0),
        },
      },
    },
    ...(isJoint && {
      coapplicant: {
        taxId: get(data, 'ssn'),
        fullName: { firstName: get(data, 'firstName'), lastName: get(data, 'lastName') },
        dateOfBirth: getFormattedBirthDate(get(data, 'dateBirth')),
        phoneNumbers: {
          primary: {
            telephoneNumber: convertToPhone(get(data, 'phoneNumber', '')),
            type: 'Mobile',
          },
        },
        emailAddress: get(data, 'email'),
        expenses: [
          {
            monthlyExpenseAmount: toNumber(get(data, 'monthlyRent', '').replace(/[$,.]/g, '')),
            expenseType: getExpenceType(get(data, 'status')),
          },
        ],
        income: {
          annualGrossAmount: toNumber(get(data, 'annualIncome', '').replace(/[$,.]/g, '')),
          otherIncomes: [
            {
              annualAmount: toNumber(get(data, 'additionalAnnualIncome', '').replace(/[$,.]/g, '')),
              type: 'Other',
            },
          ],
        },
        addressHistory: {
          currentAddress: {
            address: {
              addressLine1: get(data, 'homeAddress'),
              city: get(data, 'city'),
              stateCode: get(data, 'state'),
              postalCode: get(data, 'zip'),
            },
            propertyType: get(data, 'status'),
            monthsResiding: toNumber(get(data, 'years[0]', 0)) * 12 + get(data, 'monthsCount', 0),
          },
        },
        employmentHistory: {
          currentStatus: EMPLOYMENT_STATUS_API_VALUES[get(data, 'employmentStatus', '')],
          primary: {
            employerName: get(data, 'employer', ''),
            jobTitle: get(data, 'jobTitle'),
            monthsEmployed: toNumber(get(data, 'years[1]', 0)) * 12 + get(data, 'month', 0),
          },
        },
      },
    }),
    consentText: `${TERM_LIST.reduce((text, termPart) => `${text} ${termPart}`, '')
      .trim()
      .replace('TERMS_LINK', 'Edmunds’ Privacy Statement for details')} Terms: ${CONSENT_TEXT}`,
  };
}

export const getCustomerLookupAttributes = data => ({
  customerLookupAttributes: {
    emailAddress: get(data, 'email', ''),
    lastFourTaxId: get(data, 'ssnHidden', ''),
    lastName: get(data, 'lastName', ''),
    phoneNumber: convertToPhone(clearPhone(get(data, 'phoneNumber', ''))),
    postalCode: get(data, 'zip', ''),
  },
  filterCriteria: {
    applicationDecisionStatus: ['Approved'],
  },
  sortCriteria: {
    sortBy: 'applicationDecisionedTimestamp',
    sortOrder: 'descending',
  },
});

export const getFormattedOfferData = ({ data, calculatorSelections, lenderId, taxesAndFees = {} }) => ({
  vehicleStructures: [
    {
      vehicle: {
        vin: get(data, 'vin'),
        make: get(data, 'make'),
        model: get(data, 'model'),
        year: get(data, 'year'),
        condition: get(data, 'condition'),
        mileage: get(data, 'mileage'),
      },
      dealStructure: {
        salesPrice: get(data, 'salesPrice'),
        downPayment: getCleanValue(get(data, 'downPayment', '')) || get(calculatorSelections, 'downPayment', 1000),
        vehicleTradeInDetail: {
          make: get(calculatorSelections, 'make', ''),
          model: get(calculatorSelections, 'model', ''),
          year: get(calculatorSelections, 'year', ''),
          vehicleValuationAmount: get(calculatorSelections, 'tradeIn', 0),
          remainingBalance: get(calculatorSelections, 'tradeInOwedAmount', 0),
        },
      },
      fees: [
        {
          feeAmount: get(taxesAndFees, 'fees.docFee', 0),
          feeType: 'DocumentFee',
          productCategory: 'FrontEnd',
        },
        {
          feeAmount: get(taxesAndFees, 'total', 0),
          feeType: 'TaxTitleLicenseFee',
          productCategory: 'BackEnd',
        },
      ],
    },
  ],
  loanTerms: TERMS,
  ...(lenderId && { lenderIds: [lenderId] }),
  consentText: CONSENT_TEXT,
});

export const getFinancingLeadSubmissionInfo = ({
  sessionId,
  visitorId,
  vin,
  vehicle,
  financingOffer,
  calculatorSelections,
  personalInfo,
}) => {
  const { firstName, lastName, email, phoneNumber, zipCode } = personalInfo;
  const financeInformation = get(financingOffer, 'financeInformation', {});

  const {
    prices: { baseMsrp },
    dealerInfo,
    type,
  } = vehicle;
  const { franchiseId, rooftopId } = dealerInfo;
  const { monthlyPayment, buyRate, loanTerm } = financeInformation;
  const make = get(vehicle, 'vehicleInfo.styleInfo.make');
  const model = get(vehicle, 'vehicleInfo.styleInfo.model');
  const year = get(vehicle, 'vehicleInfo.styleInfo.year');
  const bodyType = get(vehicle, 'vehicleInfo.styleInfo.bodyType', '');
  const defaultPhoto = getNoVehicleImageToDisplay(bodyType);
  const { photoUrls } = getPhotoConfig(vehicle, DEFAULT_WIDTH, null);
  const photoUrl = photoUrls[0];
  const vdpLink = vdpLinkBuilder({ make, model, year, vin });
  const tags = ['#financing', '#capOne'];
  const tradeInEquity = get(calculatorSelections, 'tradeIn', 0) - get(calculatorSelections, 'tradeInOwedAmount', 0);
  const tradeInEquityDescription = tradeInEquity < 0 ? `${TOTAL_TRADE_IN_MSG} ${NEGATIVE_TRADE_IN_MSG}` : '';
  const offerId = get(financingOffer, 'vehicleOfferId', '');

  const leads = [
    {
      apr: buyRate,
      comments: `This shopper has been pre-qualified by ${
        LENDERS[get(financingOffer, 'lenderId', 'COF')]
      } for the following terms, and is looking for an expedited purchase process.`,
      dealerId: rooftopId,
      downPayment: get(calculatorSelections, 'downPayment', 1000),
      financeIntent: 'PURCHASE',
      franchiseId,
      leadDetails: {
        bannerCode: Date.now(),
        sessionId,
        visitorId,
        tags,
        approvedStatus: true,
        creativeId: `${type.toLocaleLowerCase()}-financing_lead-financing_results`,
        prequalifyPresented: true,
      },
      monthlyPayment,
      msrp: baseMsrp,
      purchaseOffer: {
        annualPercentageRate: buyRate,
        downPayment: get(calculatorSelections, 'downPayment', 1000),
        monthlyPayment,
        sellingPrice: get(vehicle, 'prices.displayPrice', 0),
        tradeIn: get(calculatorSelections, 'tradeIn', 0),
        term: loanTerm,
      },
      refferingUrls: {
        cbp: `https://www.edmunds.com/financing/${vin}`,
        vdp: `https://www.edmunds.com${vdpLink}`,
      },
      sellingPrice: get(vehicle, 'prices.displayPrice', 0),
      tradeInDetails: {
        appraisalValue: get(calculatorSelections, 'tradeIn', 0),
        amountOwed: get(calculatorSelections, 'tradeInOwedAmount', 0),
        appraisalVehicle: getAppraisalMMYLabel({
          make: get(calculatorSelections, 'make', ''),
          model: get(calculatorSelections, 'model', ''),
          year: get(calculatorSelections, 'year', ''),
          tradeIn: get(calculatorSelections, 'tradeIn', 0),
        }),
        tradeInEquity,
        tradeInLimitDescription: APPLIED_TRADE_IN_MSG,
        tradeInEquityDescription,
        appliedTradeInEquity: get(calculatorSelections, 'tradeIn', 0),
      },
      totalRebate: 0,
      uniqueId: offerId,
      vin,
      vehicleStatus: type,
    },
  ];

  const emailTokens = {
    'my.leadData': JSON.stringify({
      shopper: { firstName },
      dealer: {
        name: get(dealerInfo, 'name', ''),
        phoneNumber: get(dealerInfo, 'phoneNumbers.trackable', get(dealerInfo, 'phoneNumbers.basic', {})),
        address: get(dealerInfo, 'address', {}),
      },
      inventory: {
        vin,
        make,
        model,
        year,
        trim: get(vehicle, 'vehicleInfo.styleInfo.trim', ''),
        engineSize: get(vehicle, 'vehicleInfo.partsInfo.engineSize'),
        cylinders: get(vehicle, 'vehicleInfo.partsInfo.cylinders'),
        stockNumber: get(vehicle, 'stockNumber'),
        photoUrl: photoUrl || defaultPhoto,
      },
      purchaseOffer: {
        monthlyPayment: formatPriceString(monthlyPayment),
        term: loanTerm,
        annualPercentageRate: buyRate,
        displayPrice: get(vehicle, 'prices.displayPrice', 0),
        downPayment: formatPriceString(get(calculatorSelections, 'downPayment', 0)),
        tradeIn: formatPriceString(
          get(calculatorSelections, 'tradeIn', 0) - get(calculatorSelections, 'tradeInOwedAmount', 0)
        ),
        offerId,
        expireDate: dateFormat(addDaysToCurrentDate(GO_EXPIRATION_PRE_QUALIFICATION_DAYS), 'mm/dd/yyyy'),
      },
    }),
  };

  return {
    submissionInfo: {
      ...getLeadSubmissionInfo({
        leads,
        creativeId: `${type.toLocaleLowerCase()}-financing_lead-financing_results`,
        pathname: `/financing/${vin}`,
        visitorId,
        leadTargets: leads,
        sessionId,
        tags,
        zipCode,
        phone: phoneNumber,
        email,
        firstName,
        lastName,
      }),
      testLead: firstName === 'SendTestLead' || lastName === 'SendTestLead',
      sendToPartnerQA: false,
    },
    emailTokens,
  };
};

export function getConsumerEmailData({ status }) {
  const expireDate = dateFormat(addDaysToCurrentDate(GO_EXPIRATION_PRE_QUALIFICATION_DAYS), 'mm/dd/yyyy');

  return {
    'my.emailType': status,
    'my.expireDate': expireDate,
  };
}

export function getPrequalEmailData({ vehicle, vin, storageData, dealerInfo, offer }) {
  const { firstName, id } = storageData;
  const monthlyPayment = get(offer, 'selectedOffer.monthlyPayment', 0);
  const loanTerm = get(offer, 'selectedOffer.loanTerm', 0);
  const downPayment = get(offer, 'downPayment', 0);
  const tradeIn = get(offer, 'tradeIn', 0);
  const annualPercentageRate = get(offer, 'selectedOffer.buyRate', 0);
  const dealerInfoData = get(vehicle, 'dealerInfo', dealerInfo);
  const make = get(vehicle, 'vehicleInfo.styleInfo.make', '');
  const model = get(vehicle, 'vehicleInfo.styleInfo.model', '');
  const year = get(vehicle, 'vehicleInfo.styleInfo.year', '');
  const bodyType = get(vehicle, 'vehicleInfo.styleInfo.bodyType', '');
  const defaultPhoto = getNoVehicleImageToDisplay(bodyType);
  const { photoUrls } = getPhotoConfig(vehicle, DEFAULT_WIDTH, null);
  const photoUrl = photoUrls[0];
  const workHours = get(vehicle, 'dealerInfo.workHours', get(dealerInfo, 'workHours', {}));
  const expireDate = dateFormat(addDaysToCurrentDate(GO_EXPIRATION_PRE_QUALIFICATION_DAYS), 'mm/dd/yyyy');

  return {
    'my.firstName': firstName,
    'my.dealer': JSON.stringify({
      dealerName: get(dealerInfoData, 'name', ''),
      phoneNumber: get(dealerInfoData, 'phoneNumbers.trackable', get(dealerInfoData, 'phoneNumbers.basic', {})),
      address: get(dealerInfoData, 'address', {}),
      dealerWorkHours: getShortcutWorkingHours(workHours),
    }),
    'my.inventory': JSON.stringify({
      vin,
      make,
      model,
      year,
      trim: get(vehicle, 'vehicleInfo.styleInfo.trim', ''),
      engineSize: get(vehicle, 'vehicleInfo.partsInfo.engineSize'),
      cylinders: get(vehicle, 'vehicleInfo.partsInfo.cylinders'),
      stockNumber: get(vehicle, 'stockNumber'),
      photoUrl: photoUrl || defaultPhoto,
    }),
    'my.offer': JSON.stringify({
      monthlyPayment: formatPriceString(monthlyPayment),
      term: loanTerm,
      annualPercentageRate,
      downPayment: formatPriceString(downPayment),
      tradeIn: formatPriceString(tradeIn),
    }),
    'my.id': id,
    'my.expireDate': expireDate,
  };
}

export function getUserData(data) {
  return {
    firstName: get(data, 'personal.firstNamePrimary', ''),
    lastName: get(data, 'personal.lastNamePrimary', ''),
    email: get(data, 'personal.emailPrimary'),
    phoneNumber: get(data, 'personal.phoneNumberPrimary', ''),
    zipCode: get(data, 'residence.zipPrimary', ''),
  };
}

export function getLimitValue(limitValue, units) {
  if (units === 'USD') {
    return formatPriceString(limitValue);
  }
  return `${limitValue} ${UNIT_DEFINITION[`${units}`]}`;
}

export function getErrorMessage({ offer, counter, counterName }) {
  const limitValue = get(offer, 'counters[0].suggestedValue.value');
  const units = get(offer, 'counters[0].suggestedValue.unit');
  const counterType = get(offer, 'counters[0].counterType');
  const problemType = get(COUNTER_DEFINITIONS, `${counter}.problemType["${counterType}"]`);

  const value = getLimitValue(limitValue, units);

  if (counterName === 'APR') {
    return `${counterName} exceeds state requirements. ${counterName} must be ${value} or lower.`;
  }

  const decisionMsg = problemType === 'low' ? `at least ${value}` : `${value} or lower`;

  return `${counterName} is too ${problemType}. ${counterName} must be ${decisionMsg}.`;
}

export const getCounterTrigger = offers => {
  const offerWithTrigger = offers.find(offer => !!get(offer, 'counters[0].counteredOn'));

  return get(offerWithTrigger, 'counters[0].counteredOn');
};

export function getGroupedOffers(offers, trigger) {
  const { true: firstCounterTriggerOffers, false: secondCounterTriggerOffers } = groupBy(
    offers,
    offer => get(offer, 'counters[0].counteredOn') === trigger
  );

  return { firstCounterTriggerOffers, secondCounterTriggerOffers };
}

export function getCounterNames(offers, trigger) {
  const { firstCounterTriggerOffers, secondCounterTriggerOffers } = getGroupedOffers(offers, trigger);
  const firstCounterTrigger = getCounterTrigger(firstCounterTriggerOffers);
  const secondCounterTrigger = getCounterTrigger(secondCounterTriggerOffers);
  return {
    firstCounterName: get(COUNTER_DEFINITIONS, `${firstCounterTrigger}.counterName`),
    secondCounterName: get(COUNTER_DEFINITIONS, `${secondCounterTrigger}.counterName`),
  };
}

export function verifyOffers(offers) {
  let showMessage = false;
  let messageType = '';
  let message = '';
  let counterNames = [];
  let isOffersAvailable = false;

  const isAllOffersAvailable = offers.every(offer => get(offer, 'responseCode') === SUCCESS_CODE);

  if (isAllOffersAvailable)
    return {
      showMessage,
      type: messageType,
      messages: [message],
      counterNames,
      isOffersAvailable: true,
    };

  const isSomeOffersAvailable = offers.some(offer => get(offer, 'responseCode') === SUCCESS_CODE);

  if (isSomeOffersAvailable) {
    showMessage = true;
    messageType = 'notification';
    message = NOTIFICATION_MSG;
    isOffersAvailable = true;

    const offersWithCounters = offers.filter(offer => get(offer, 'responseCode') !== SUCCESS_CODE);
    const counterTrigger = getCounterTrigger(offersWithCounters);
    const isOneTriggerForAllOffers = offersWithCounters.every(
      offer => get(offer, 'counters[0].counteredOn') === counterTrigger
    );
    if (isOneTriggerForAllOffers) {
      counterNames = [get(COUNTER_DEFINITIONS, `${counterTrigger}.counterName`)];
    } else {
      const { firstCounterName, secondCounterName } = getCounterNames(offers, counterTrigger);
      counterNames = [firstCounterName, secondCounterName];
    }
  } else {
    showMessage = true;
    const counterTrigger = getCounterTrigger(offers);

    if (!counterTrigger) {
      return {
        showMessage,
        type: 'notification',
        messages: [`${LENDERS[`${get(offers[0], 'lenderId')}`]}${NOTIFICATION_MSG_NO_COUNTERS}`],
        isOffersAvailable,
        counterNames,
      };
    }

    messageType = 'warning';
    const isOneTriggerForAllOffers = offers.every(offer => get(offer, 'counters[0].counteredOn') === counterTrigger);

    if (isOneTriggerForAllOffers) {
      const counterName = get(COUNTER_DEFINITIONS, `${counterTrigger}.counterName`);
      const offerWithMinValue = minBy(offers, item => item.counters[0].suggestedValue.value);

      return {
        showMessage,
        type: messageType,
        messages: [getErrorMessage({ offer: offerWithMinValue, counter: counterTrigger, counterName })],
        counterNames: [counterName],
        isOffersAvailable,
      };
    }

    const { firstCounterTriggerOffers, secondCounterTriggerOffers } = getGroupedOffers(offers, counterTrigger);
    const firstCounterTrigger = getCounterTrigger(firstCounterTriggerOffers);
    const secondCounterTrigger = getCounterTrigger(secondCounterTriggerOffers);
    const { firstCounterName, secondCounterName } = getCounterNames(offers, counterTrigger);

    return {
      showMessage,
      type: messageType,
      messages: [
        getErrorMessage({
          offer: firstCounterTriggerOffers[0],
          counter: firstCounterTrigger,
          counterName: firstCounterName,
        }),
        getErrorMessage({
          offer: secondCounterTriggerOffers[0],
          counter: secondCounterTrigger,
          counterName: secondCounterName,
        }),
      ],
      counterNames: [firstCounterName, secondCounterName],
      isOffersAvailable,
    };
  }

  return { showMessage, type: messageType, messages: [message], counterNames, isOffersAvailable };
}

export function createErrorPath(step, location) {
  const pathPrefixStep = step === LOOK_UP_STEP ? PERSONAL_STEP : step;
  const pathPrefix = getPathPrefix(location, pathPrefixStep);
  return createPath(pathPrefix, ERROR_STEP, location, {});
}

export const isPreviousPreQualNotFoundError = error => PRE_QUAL_NOT_FOUND_ERROR_REGEX.test(get(error, 'message', ''));

export const isInvalidValue = value => value.replace(/\D/, '') < REVIEW_CONFIRMATION_FIELDS_MIN_VALUE;
export const isConfirmMode = (card, fields) => {
  const cardName = card.toLowerCase();

  if (cardName === RESIDENCE_STEP || cardName === EMPLOYMENT_STEP) {
    return (
      !!fields && CONFIRMATION_DATA[cardName].fields.some(field => !!(fields[field] && isInvalidValue(fields[field])))
    );
  }

  return false;
};

export const getInvalidField = (name, fields) =>
  CONFIRMATION_DATA[name].fields.find(field => !!fields[field] && isInvalidValue(fields[field]));

export const isVehicleNotFoundError = error => /VehicleNotFound/.test(get(error, 'message', ''));

export const getErrorStatus = e =>
  isVehicleNotFoundError(e) ? { status: ERROR_STATUS.VEHICLE_NOT_FOUND } : { status: ERROR_STATUS.SYSTEM_ERROR };
