/**
 * Returns taxes & fees api url.
 * @param {string} zipCode
 * @param {string} styleId
 * @param {number} salesPrice
 * @param {number} tradeIn
 * @param {number} marketValue
 * @returns {string}
 */

export const getTaxesFeesApiUrl = ({ zipCode, styleId, salesPrice = 0, tradeIn = 0, marketValue = 0 }) =>
  `/taxesfees/v1/?zipcode=${zipCode}&styleid=${styleId}&purchaseprice=${salesPrice}&tradeinvalue=${tradeIn}&marketvalue=${marketValue}&primarycustomercash=0`;
