import STS from 'aws-sdk/clients/sts';
import { ClientConfig } from 'client/configuration';
import { getDefaultApiHeaders } from 'site-modules/shared/utils/car-buying/api-helper';
import aws4 from 'aws4';

export async function awsServiceAccountSignedOptions(data, apiPath) {
  const sts = new STS();
  const stsParams = {
    RoleArn: ClientConfig.get('carBuying').creditAPI.assumeRoleArn,
    RoleSessionName: ClientConfig.get('carBuying').creditAPI.assumeRoleSessionName,
    DurationSeconds: ClientConfig.get('carBuying').creditAPI.assumeRoleDurationSeconds,
  };
  const assumedRole = await sts.assumeRole(stsParams).promise();
  const apiHost = ClientConfig.get('carBuying').capOneAPI.host;
  const apiDefaultTimeoutMs = ClientConfig.get('carBuying').capOneAPI.apiTimeoutMs;

  const options = {
    service: 'execute-api',
    host: apiHost,
    path: apiPath,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      ...getDefaultApiHeaders(),
    },
    body: JSON.stringify(data),
    timeout: apiDefaultTimeoutMs,
    showAPIError: true,
    ecdhCurve: 'auto',
  };

  const credentials = {
    accessKeyId: assumedRole.Credentials.AccessKeyId,
    secretAccessKey: assumedRole.Credentials.SecretAccessKey,
    sessionToken: assumedRole.Credentials.SessionToken,
  };
  const signedOptions = aws4.sign(options, credentials);

  return { options, signedOptions };
}
