import { get } from 'lodash';

const shortcutWeekDays = {
  monday: 'Mon',
  tuesday: 'Tue',
  wednesday: 'Wed',
  thursday: 'Thu',
  friday: 'Fri',
  saturday: 'Sat',
  sunday: 'Sun',
};
const isOpenCloseTimeEqual = (periodDays, workingHours) =>
  periodDays.startTime === workingHours.open && periodDays.endTime === workingHours.close;

const resetStartDay = (workingHours, shortcut) => {
  const periodDays = {};
  periodDays.startDay = shortcut || '';
  periodDays.startTime = get(workingHours, 'open', '');
  periodDays.endTime = get(workingHours, 'close', '');
  periodDays.endDay = '';
  return periodDays;
};
const getPeriodStringFormPeriodDays = (periodDays, hideStandardTime = false) =>
  periodDays.reduce((periodArr, timeline) => {
    const startTime = timeline.startTime
      .replace(/:00/, '')
      .match(/^0?(.*)/)[1]
      .split(' ')
      .join('')
      .toLowerCase();
    const endTime = timeline.endTime
      .replace(/:00/, '')
      .match(/^0?(.*)/)[1]
      .split(' ')
      .join('')
      .toLowerCase();
    const resultString =
      timeline.startDay && timeline.endDay
        ? `${timeline.startDay}-${timeline.endDay} ${startTime}-${endTime}${hideStandardTime ? '' : ' ET'}`
        : `${timeline.startDay} ${startTime}-${endTime}${hideStandardTime ? '' : ' ET'}`;
    periodArr.push(resultString);
    return periodArr;
  }, []);

/**
 * @param {Obj} {monday: {open: string, close: string}}
 * @param {boolean} hideStandardTime
 * @returns {Arr} ['Mon-Fri 9am-9pm ET', 'Sat 11am-3pm ET,...] or ['Mon-Fri 9am-9pm', 'Sat 11am-3pm,...]
 */
export const getShortcutWorkingHours = (workingHours, hideStandardTime = false) => {
  if (!workingHours) return [];
  let periodDays = {
    startDay: '',
    endDay: '',
    startTime: '',
    endTime: '',
  };
  const shortcutWeekDaysArr = Object.entries(shortcutWeekDays);
  const shortcutWorkingHours = [];
  shortcutWeekDaysArr.forEach((item, i) => {
    const [fullName, shortcut] = item;
    // day off
    if (!workingHours[fullName]) {
      if (!periodDays.startDay) return;
      shortcutWorkingHours.push(periodDays);
      periodDays = resetStartDay();
      return;
    }
    if (!periodDays.startDay) {
      periodDays = resetStartDay(workingHours[fullName], shortcut);
      return;
    }
    if (
      ((!periodDays.endDay && shortcut !== periodDays.startDay) || (periodDays.startDay && periodDays.endDay)) &&
      isOpenCloseTimeEqual(periodDays, workingHours[fullName])
    ) {
      periodDays.endDay = shortcut;
    } else {
      shortcutWorkingHours.push(periodDays);
      periodDays = resetStartDay(workingHours[fullName], shortcut);
    }
    // last day
    if (!shortcutWeekDaysArr[i + 1]) shortcutWorkingHours.push(periodDays);
  });

  return getPeriodStringFormPeriodDays(shortcutWorkingHours, hideStandardTime);
};
