export function getPathPrefix({ pathname }, activeStep) {
  return pathname.replace(activeStep, '').replace('//', '/');
}

export function createPath(pathPrefix, path, { search }, params) {
  const transformedPathPrefix = pathPrefix.endsWith('/') ? pathPrefix : `${pathPrefix}/`;
  let updatedSearch = search;
  let additionalParams = '';

  if (params && params.excluded) {
    const regexp = new RegExp(`(${params.excluded.map(param => `(\\?|&)${param}`).join('|')})`, 'g');
    updatedSearch = updatedSearch.replace(regexp, '').replace(/^&/, '?');
  }

  if (params && params.active) {
    additionalParams = `${updatedSearch ? '&' : '?'}${params.active.join('&')}`;
  }

  const pathParam = path ? `${path}/` : '';

  return `${transformedPathPrefix}${pathParam}${updatedSearch}${additionalParams}`;
}

/**
 * Helper to create a path fo the specific path using current location.
 * @param {string} activeStep  current active step name
 * @param {string} pathToChange  the step name which should be in path
 * @param {object} location
 * @param {object} params
 * @returns {string} path
 */

export function createLinkForStep(activeStep, pathToChange, location, params) {
  const pathPrefix = getPathPrefix(location, activeStep);
  return createPath(pathPrefix, pathToChange, location, params);
}

/**
 * Helper to create a path to the next step from current step
 * @param {string} currentStep - current step name
 * @param {object} location
 * @param {object} params
 * @param {boolean} navigateToLastStep
 * @returns {string} path name
 */
export function createLinkForTheNextStep(currentStep, location, params, navigateToLastStep = false) {
  const pathPrefix = getPathPrefix(location, currentStep.path);

  if (currentStep && currentStep.lastPath && navigateToLastStep) {
    const nextStep = currentStep.lastPath;
    return createPath(pathPrefix, nextStep, location, params);
  }
  if (currentStep && currentStep.nextPath) {
    const nextStep = currentStep.nextPath;

    return createPath(pathPrefix, nextStep, location, params);
  }

  return null;
}
