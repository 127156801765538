import { ADD_FINANCING_DATA } from './constants';

/**
 *
 * @param {object} financing
 * @returns {{type: string, financing: object}}
 */
export function addFinancing(financing) {
  return {
    type: ADD_FINANCING_DATA,
    financing,
  };
}
