export const STANDALONE_STEPS = ['payments', 'confirmation'];

export const BASICS_SCREEN_FLOWS = [
  {
    type: 'Individual',
    description: 'You are the only person requesting to be pre‑qualified for auto financing.',
    icon: 'icon-user',
  },
  {
    type: 'Joint',
    description: 'A second person is requesting to be pre‑qualified for auto financing with you.',
    icon: 'icon-users',
  },
];
