import { set, get } from 'lodash';
import { Storage } from 'site-modules/shared/utils/storage';

export const FINANCING_PATH = 'financing';

export const FinancingStorage = {
  /**
   * Returns local storage instance
   * @returns {Storage}
   */
  getLocalStorage() {
    if (!this.localStorage) this.localStorage = new Storage('localStorage');
    return this.localStorage;
  },

  /**
   * Returns session storage instance
   * @returns {Storage}
   */
  getSessionStorage() {
    if (!this.sessionStorage) this.sessionStorage = new Storage('sessionStorage');
    return this.sessionStorage;
  },

  /**
   * Sets value to storage by path
   * @param {Storage} storage
   * @param {String} path
   * @param value
   */
  setValue(storage, path, value) {
    const financingStorage = storage.get(FINANCING_PATH) || {};
    set(financingStorage, path, value);
    storage.set(FINANCING_PATH, financingStorage);
  },

  /**
   * Returns value from storage by path
   * @param {Storage} storage
   * @param {String} path
   * @returns value form storage
   */
  getValue(storage, path) {
    const financingStorage = storage.get(FINANCING_PATH) || {};
    return get(financingStorage, path);
  },

  /**
   * Sets value to local storage by path
   * @param {String} path
   * @param value
   */
  setValueToLocalStorage(path, value) {
    this.setValue(this.getLocalStorage(), path, value);
  },

  /**
   * Returns value from local storage by path
   * @param {String} path
   * @returns value from local storage
   */
  getValueFromLocalStorage(path) {
    return this.getValue(this.getLocalStorage(), path);
  },

  /**
   * Sets value to session storage by path
   * @param {String} path
   * @param value
   */
  setValueToSessionStorage(path, value) {
    this.setValue(this.getSessionStorage(), path, value);
  },

  /**
   * Returns value from session storage by path
   * @param {String} path
   * @returns value from session storage
   */
  getValueFromSessionStorage(path) {
    return this.getValue(this.getSessionStorage(), path);
  },
};
