import { TIME } from 'client/utils/time';
import { setCookie } from 'client/utils/set-cookie';

const FINANCING_PREQUALIFIED_COOKIE_NAME = 'fn-pr-data';

export function setFinancingPrequalifiedCookie(data) {
  document.cookie = setCookie(FINANCING_PREQUALIFIED_COOKIE_NAME, data, {
    path: '/',
    maxAge: TIME.SEC.ONE_HOUR,
  });
}

export const getFinancingPrequalifiedCookie = () => {
  const cookie = document.cookie.match(`(^|;) ?${FINANCING_PREQUALIFIED_COOKIE_NAME}=([^;]*)(;|$)`);
  return cookie && cookie[2] ? decodeURIComponent(cookie[2]) : null;
};
