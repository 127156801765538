import { isEmpty } from 'lodash';
import { CarBuyingAPI } from 'client/data/api/api-client';

export const ENCRYPT_API_URL = '/api/v1/session/encrypt/';
export const DECRYPT_API_URL = '/api/v1/session/decrypt/';

export async function getEncryptedFields(data) {
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  let encryptedScreenData;
  try {
    encryptedScreenData = await CarBuyingAPI.fetchJson(ENCRYPT_API_URL, options);
  } catch (error) {
    throw new Error(error);
  }

  return encryptedScreenData;
}

export async function getDecryptedFields(encryptedFields) {
  if (!encryptedFields || isEmpty(encryptedFields)) return {};
  let decryptedFields;
  try {
    decryptedFields = await CarBuyingAPI.fetchJson(DECRYPT_API_URL, {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify(encryptedFields),
    });
  } catch (error) {
    return {};
  }

  return decryptedFields;
}
