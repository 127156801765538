export const INTRO_STEP = 'intro';
export const BASICS_STEP = 'basics';
export const PERSONAL_STEP = 'personal';
export const PREQUALIFY_STEP = 'prequalify';
export const RESIDENCE_STEP = 'residence';
export const EMPLOYMENT_STEP = 'employment';
export const REVIEW_STEP = 'review';
export const PAYMENTS_STEP = 'payments';
export const CONFIRMATION_STEP = 'confirmation';
export const LOOK_UP_STEP = 'lookup';
export const LOOK_UP_FAILURE_STEP = 'lookupFailure';
export const CHOOSE_ELIGIBLE_VIN = 'chooseEligibleVin';
export const ERROR_STEP = 'error';
export const DEBUG_FINANCING_API = 'debugFinancingAPI';

export const PrequalificationApplicationFlowSteps = [
  BASICS_STEP,
  PERSONAL_STEP,
  RESIDENCE_STEP,
  EMPLOYMENT_STEP,
  REVIEW_STEP,
];

export const INTRO_STEP_DATA = {
  header: 'See If You Pre-qualify for Financing',
  mobileHeader: 'See If You Pre-qualify',
  subheader:
    'Edmunds has partnered with Capital One to help you explore your financing options and buy with confidence.',
  name: 'Intro',
  path: INTRO_STEP,
  nextPath: BASICS_STEP,

  showFinancingVehicleInfo: true,
  showHeader: true,
  showNextBtn: true,
  btnText: 'Get Started',
  showPrequalifyCta: true,
  showDisclaimer: true,
};

export const BASICS_STEP_DATA = {
  header: 'Let’s start with the basics',
  subheader:
    'Are you the only person requesting to be pre-qualified for auto financing, or is someone else requesting with you?',
  name: 'Basics',
  path: BASICS_STEP,
  prevPath: INTRO_STEP,
  nextPath: PERSONAL_STEP,
  order: 1,

  showFinancingVehicleInfo: true,
  showNavigation: true,
  showHeader: true,
  showNextBtn: true,
  btnText: 'Next',
  showPrequalifyCta: true,
  showDisclaimer: true,
};
export const PERSONAL_STEP_DATA = {
  header: 'Tell us a little about yourself',
  name: 'Personal',
  path: PERSONAL_STEP,
  prevPath: BASICS_STEP,
  nextPath: RESIDENCE_STEP,
  order: 2,

  showFinancingVehicleInfo: true,
  showNavigation: true,
  showHeader: true,
  showBackBtn: true,
  showNextBtn: true,
  btnText: 'Next',
  showPrequalifyCta: true,
};
export const PERSONAL_LOOKUP_STEP_DATA = {
  name: 'Personal Look Up',
  originalPath: LOOK_UP_STEP,
  path: PERSONAL_STEP,
  prevPath: BASICS_STEP,
  nextPath: PAYMENTS_STEP,

  showFinancingVehicleInfo: true,
};
export const PERSONAL_LOOKUP_FAILURE_STEP_DATA = {
  name: 'Personal Look Up Failure',
  path: PERSONAL_STEP,
  originalPath: LOOK_UP_FAILURE_STEP,
  nextPath: BASICS_STEP,

  showFinancingVehicleInfo: true,
};
export const RESIDENCE_STEP_DATA = {
  header: 'Tell us about where you live',
  name: 'Residence',
  path: RESIDENCE_STEP,
  prevPath: PERSONAL_STEP,
  nextPath: EMPLOYMENT_STEP,
  order: 3,

  showFinancingVehicleInfo: true,
  showNavigation: true,
  showHeader: true,
  showBackBtn: true,
  showNextBtn: true,
  btnText: 'Next',
  showPrequalifyCta: true,
};
export const EMPLOYMENT_STEP_DATA = {
  header: 'Tell us about your employment & income',
  name: 'Employment',
  path: EMPLOYMENT_STEP,
  prevPath: RESIDENCE_STEP,
  nextPath: REVIEW_STEP,
  order: 4,

  showFinancingVehicleInfo: true,
  showNavigation: true,
  showHeader: true,
  showBackBtn: true,
  showNextBtn: true,
  btnText: 'Next',
  showPrequalifyCta: true,
};
export const REVIEW_STEP_DATA = {
  header: 'Before we continue, let’s double check everything',
  name: 'Review',
  path: REVIEW_STEP,
  prevPath: EMPLOYMENT_STEP,
  nextPath: PAYMENTS_STEP,
  lastPath: CONFIRMATION_STEP,
  order: 5,

  showFinancingVehicleInfo: true,
  showNavigation: true,
  showHeader: true,
};
export const PAYMENTS_STEP_DATA = {
  header: 'Great News! You’ve prequalified for financing.',
  name: 'Payments',
  path: PAYMENTS_STEP,
  prevPath: REVIEW_STEP,
  nextPath: CONFIRMATION_STEP,

  showFinancingResults: true,
};
export const CONFIRMATION_STEP_DATA = {
  name: 'Confirmation',
  path: CONFIRMATION_STEP,
  prevPath: PAYMENTS_STEP,
  nextPath: '',

  showConfirmationHeader: true,
};

export const ERROR_STEP_DATA = {
  name: 'Error',
  path: ERROR_STEP,
  nextPath: '',
};

export const STEPS = [
  INTRO_STEP_DATA,
  BASICS_STEP_DATA,
  PERSONAL_STEP_DATA,
  PERSONAL_LOOKUP_STEP_DATA,
  PERSONAL_LOOKUP_FAILURE_STEP_DATA,
  RESIDENCE_STEP_DATA,
  EMPLOYMENT_STEP_DATA,
  REVIEW_STEP_DATA,
  PAYMENTS_STEP_DATA,
  CONFIRMATION_STEP_DATA,
];

export const STEPS_MAP = {
  [INTRO_STEP]: INTRO_STEP_DATA,
  [BASICS_STEP]: BASICS_STEP_DATA,
  [PERSONAL_STEP]: PERSONAL_STEP_DATA,
  [LOOK_UP_STEP]: PERSONAL_LOOKUP_STEP_DATA,
  [LOOK_UP_FAILURE_STEP]: PERSONAL_LOOKUP_FAILURE_STEP_DATA,
  [RESIDENCE_STEP]: RESIDENCE_STEP_DATA,
  [EMPLOYMENT_STEP]: EMPLOYMENT_STEP_DATA,
  [REVIEW_STEP]: REVIEW_STEP_DATA,
  [PAYMENTS_STEP]: PAYMENTS_STEP_DATA,
  [CONFIRMATION_STEP]: CONFIRMATION_STEP_DATA,
  [ERROR_STEP]: ERROR_STEP_DATA,
};
