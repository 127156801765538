import { get } from 'lodash';
import {
  MOCK_NAME,
  MOCK_PREQUALIFY_ID,
  MOCK_APPROVED_RESPONSE,
} from 'site-modules/shared/constants/financing/mock-financing-data';
import { addFinancing } from 'client/actions/financing';

export function isMockId(id) {
  return id === MOCK_PREQUALIFY_ID;
}
export function shouldMockResponse(data) {
  return get(data, 'firstNamePrimary', get(data, 'firstName', '')) === MOCK_NAME || isMockId(get(data, 'id', ''));
}

export function sendMockResponse({ type, store }) {
  return store.dispatch(addFinancing({ ...MOCK_APPROVED_RESPONSE, type }));
}

export function sendMockOffers() {
  return MOCK_APPROVED_RESPONSE.offers;
}

export function sendMockLeadResponse() {
  return { status: 'OK' };
}
