import { validation } from 'site-modules/shared/components/form-validation/validation';

export const STORAGE_KEYS = {
  LAST_COMPLETED_STEP: 'lastCompletedStep',
  VISITED_STEPS: 'visitedSteps',
  FINANCING_OFFERS: 'financingOffers',
  VIN: 'vins["{vin}"]',
  PREQUALIFICATION_DATA: 'capOnePrequalify',
  CALCULATOR_SELECTIONS: 'financingCalculatorSelections',
  ERROR_STATUS: 'errorStatus',
  PRE_VIN_ERROR_STATUS: 'preVinErrorStatus',
  APPRAISAL_OFFER: 'appraisalOffer',
};

export const CAPITAL_ONE_LOGO_SRC = '/img/car-buying/capital1/capital_one.png';

export const DEFAULT_TERM = 60;
export const TERMS = [24, 36, 48, 60, 72];

export const VALIDATORS = {
  email: {
    test: validation.validateEmail,
    errorText: 'Please enter your correct email address.',
  },
  confirmEmail: {
    test: validation.validateEmail,
    errorText: 'Please enter your correct email address.',
  },
  firstName: {
    test: validation.validateFirstName,
    errorText: 'Please correct your first name. Special characters cannot be included.',
  },
  lastName: {
    test: validation.validateName,
    errorText: 'Please correct your last name. Special characters cannot be included.',
  },
  phoneNumber: {
    test: validation.validatePhoneNumber,
    errorText: 'Please enter a valid phone number.',
  },
  dateBirth: {
    test: validation.validateDateOfBirth,
    errorText: 'Please enter a valid date of birth',
  },
  employmentStatus: {
    test: validation.validateValue,
    errorText: 'Please enter your employment status.',
  },
  employmentStatusPrimary: {
    test: validation.validateValue,
    errorText: 'Please enter your employment status.',
  },
  employer: {
    test: validation.validateValue,
    errorText: 'Please enter employer name.',
  },
  employerPrimary: {
    test: validation.validateValue,
    errorText: 'Please enter employer name.',
  },
  jobTitle: {
    test: validation.validateValue,
    errorText: 'Please enter job title.',
  },
  jobTitlePrimary: {
    test: validation.validateValue,
    errorText: 'Please enter job title.',
  },
  workedYears: {
    test: validation.validateDigits,
    errorText: 'Please enter correct value.',
  },
  workedYearsPrimary: {
    test: validation.validateDigits,
    errorText: 'Please enter correct value.',
  },
  workedMonths: {
    test: validation.validateDigits,
    errorText: 'Please enter correct value.',
  },
  workedMonthsPrimary: {
    test: validation.validateDigits,
    errorText: 'Please enter correct value.',
  },
  annualIncome: {
    test: validation.validateValue,
    errorText: 'Please enter correct value.',
  },
  annualIncomePrimary: {
    test: validation.validateValue,
    errorText: 'Please enter correct value.',
  },
  additionalAnnualIncome: {
    test: validation.validateValue,
    errorText: 'Please enter correct value.',
    isOptional: true,
  },
  additionalAnnualIncomePrimary: {
    test: validation.validateValue,
    errorText: 'Please enter correct value.',
    isOptional: true,
  },
  homeAddress: {
    test: validation.validateAddress,
    errorText: 'Please enter your correct home address.',
  },
  aptNum: {
    test: validation.validateValue,
    errorText: 'Please enter your correct apt number.',
    isOptional: true,
  },
  city: {
    test: validation.validateCity,
    errorText: 'Please enter your correct city',
  },
  zip: {
    test: validation.validateZip,
    errorText: 'Please correct your zip.',
  },
  years: {
    test: validation.validateDigits,
    errorText: 'Please enter correct value.',
  },
  monthlyRent: {
    test: validation.validateValue,
    errorText: 'Please enter correct value.',
  },
};

export const SSN_FIELD_MASK = { pattern: '***-**-****', toValue: true };
export const SSN_LAST_FOUR_FIELD_MASK = { pattern: '****', toValue: true };
export const DATE_OF_BIRTH_MASK = { pattern: '9999-99-99', toValue: true };
export const PHONE_MASK = { pattern: '(999) 999-9999', toValue: true };
export const OWN_BTN = 'Own';
export const RENT_BTN = 'Rent';
export const OTHER_BTN = 'Other';

export const OWN_OR_RENT_OPTIONS = [OWN_BTN, RENT_BTN, OTHER_BTN];

export const YES_BTN = 'Yes';
export const NO_BTN = 'No';

export const COBORROWER_OPTIONS = [YES_BTN, NO_BTN];

export const RENT_MONTH = Array.from({ length: 12 }, (_, i) => i).map(month => ({
  value: month.toString(),
  label: month,
}));
export const WORKED_MONTHS = Array.from({ length: 12 }, (_, i) => i).map(month => ({
  value: month.toString(),
  label: month,
}));

export const EMPLOYMENT_STATUS = [
  { label: 'Employed', value: 'full-time' },
  { label: 'Self Employed / 1099 Employee', value: 'self-employed' },
  { label: 'Unemployed', value: 'unemployed' },
  { label: 'Retired', value: 'retired' },
  { label: 'Other', value: 'other' },
];

export const EMPLOYMENT_STATUS_API_VALUES = {
  'full-time': 'FullTimeEmployed',
  'self-employed': 'SelfEmployed',
  unemployed: 'Unemployed',
  retired: 'Retired',
  other: 'Other',
};

export const CARD_TITLE = ['Basics', 'Personal', 'Residence', 'Employment'];

export const REVIEW_TRACKING_PARENT = {
  Basics: 'review_basics',
  Personal: 'review_personal',
  Residence: 'review_residence',
  Employment: 'review_employment',
};

export const FINANCING_CONTAINER_FIELDS_STORAGE_PATH = 'financingContainer';

export const PAYMENT_FEATURES = ['Monthly Payments', 'APR', 'Term'];

export const PAYMENT_DETAILS = [
  { name: 'Vehicle Price', niceName: 'displayPrice' },
  { name: 'Taxes and Fees', niceName: 'taxesAndFees' },
  { name: 'Down Payment', niceName: 'downPayment' },
  { name: 'Net Trade-In Value', niceName: 'tradeIn' },
];

export const CONFIRMATION_PAYMENT_DETAILS = [
  { name: 'Vehicle Price', niceName: 'displayPrice' },
  { name: 'Down Payment', niceName: 'downPayment' },
  { name: 'Net Trade-in Value', niceName: 'tradeIn' },
  { name: 'APR', niceName: 'buyRate', amount: '%' },
  { name: 'Term', niceName: 'loanTerm', amount: ' months' },
];

export const API_CALL_ID = 'capOne';

export const PREQUALIFY_API = {
  OFFERS_POOLING: '/offers-polling',
  PREQUALIFY: '/prequalify/search',
  PRICING: '/vehicle-pricing-polling/',
};
export const PREQUALIFY_API_DEV = {
  OFFERS_POOLING: '/api/v1/financing/offers-polling',
  PREQUALIFY: '/api/v1/financing/prequalify/search',
  PRICING: '/api/v1/financing/vehicle-pricing-polling/',
};

export const REQUEST_TYPE = 'Request Type';

export const DEFAULT_CALCULATOR_SELECTIONS = {
  terms: [60],
  downPayment: 1000,
};

export const DOWN_PAYMEN_COUNTER_NAME = 'Down payment';
export const ATF_COUNTER_NAME = 'Amount to finance';

export const INTRO_OPTIONS = [
  {
    id: 'credit-score',
    icon: '/img/car-buying/capital1/credit-card-checkmark.svg',
    iconWidth: 28,
    text: 'No impact on your credit score',
  },
  {
    id: 'fast-decision',
    icon: '/img/car-buying/capital1/fast-stopwatch.svg',
    iconWidth: 34,
    text: 'Get a decision in minutes',
  },
  {
    id: 'capOne-rates',
    icon: '/img/car-buying/capital1/rates.svg',
    iconWidth: 33,
    text: 'Get rates from Capital One',
  },
];
export const MOCKED_MINIMUM_DOWNPAYMENT = 1000;

export const DEFAULT_MONTH_COUNT = { value: '0', label: 0 };

export const EMPLOYMENT = 'Employment';
export const EMPLOYMENT_STATUS_TEXT = 'Employment Status';
export const RETIRED = 'Retired';
export const UNEMPLOYED = 'Unemployed';
export const GROSS_ANNUAL_INCOME = 'Gross Annual Income';

export const RETIRED_VALUE = 'retired';
export const UNEMPLOYED_VALUE = 'unemployed';

export const CONDITION = { CPO: 'CertifiedPreowned', NEW: 'New', USED: 'Used' };

export const DECLINED_ACTION_LIST = [
  {
    id: 'money_contract',
    text: 'Explore financing with other lenders',
    icon: '/img/car-buying/capital1/money_contract.svg',
  },
  { id: 'percent_money', text: 'Discuss leasing options', icon: '/img/car-buying/capital1/percent_money.svg' },
  { id: 'cash', text: 'Discuss purchasing with cash', icon: '/img/car-buying/capital1/cash.svg' },
];

export const DECLINED_ACTION_LIST_PRE_VIN = [
  {
    id: 'money_contract',
    text:
      '<a href="/inventory/srp.html?inventorytype=used%2Ccpo" target="_blank">Shop local vehicles</a> and contact local dealers for help',
    icon: '/img/car-buying/capital1/money_contract.svg',
  },
  {
    id: 'percent_money',
    text: 'Explore financing with our <a href="/calculators/affordability.html" target="_blank">calculators</a>',
    icon: '/img/car-buying/capital1/percent_money.svg',
  },
  {
    id: 'cash',
    text: '<a href="/car-loan/how-much-car-can-you-afford.html">Get advice and tips</a> on financing',
    icon: '/img/car-buying/capital1/cash.svg',
  },
];

export const MSG_WARNING = 'warning';
export const MSG_NOTIFICATION = 'notification';
export const PRE_QUAL_NOT_FOUND_ERROR_REGEX = /Not found - The resource specified by the request does not exist/;
export const LENDERS = { COF: 'Capital One' };

export const LOADING_PAYMENTS = [1, 2, 3, 4, 5];

export const EVENT_TYPES = { FINANCING_CHECK: 'Edmunds:financing:financingResponse' };
export const FINANCING_RESPONSE_TYPES = {
  UPDATE: 'update',
  PREQUALIFY: 'prequalify',
  EXISTING_PREQUALIFY: 'existing_prequalify',
};

export const REVIEW_CONFIRMATION_FIELDS = ['Monthly Housing Expense', 'Gross Annual Income'];
export const REVIEW_CONFIRMATION_FIELDS_MIN_VALUE = 10;
export const CONFIRMATION_DATA = {
  residence: {
    fields: ['monthlyRentPrimary', 'monthlyRent'],
    message: 'Confirm your monthly housing expense looks correct.',
    creativeId: 'confirm_housing-expense',
  },
  employment: {
    fields: ['annualIncomePrimary', 'annualIncome'],
    message: 'Confirm your income looks correct.',
    creativeId: 'confirm_income',
  },
};

export const CONFIRM_BTN = 'Confirm';
export const EDIT_BTN = 'Edit';

export const PRE_VIN_PATH = 'prequalify-and-shop';
export const CAPITAL_ONE_PROVIDER = 'CapitalOne';

export const TAX_NAMES = {
  citySalesTax: 'City Tax',
  countySalesTax: 'County Tax',
  districtSalesTax: 'District Tax',
  gasGuzzlerTax: 'Gas Guzzler Tax',
  stateSalesTax: 'State Tax',
};

export const FEE_NAMES = {
  dmvFee: 'Miscellaneous',
  docFee: 'Dealer Documentation/Processing Charge or Fee',
  registrationFee: 'Registration Fee',
  titleFee: 'Title Fee',
};
export const PREQUAL_MSG_APPROVED = 'approved';
export const NO_STYLE_ID_ERROR = 'Insufficient vehicle details to calculate taxes and fees';
export const WARN = 'warn';
