import { get, isEmpty, isArray } from 'lodash';
import { DEFAULT_TERM } from 'client/site-modules/shared/constants/financing/terms';
import {
  OUT_OF_POLICY_CODE,
  ELIGIBLE_YEAR_AGE,
  ELIGIBLE_MILEAGE,
  INELIGIBLE_STATE_CODES,
} from 'site-modules/shared/constants/financing/financing';
import { getCurrentDate } from 'site-modules/shared/utils/date-utils';

export const isCapOneProvider = vinData => {
  const provider = get(vinData, 'dealerInfo.productFeatures.digitalRetail.credit.provider', '');
  return provider === 'CapitalOne';
};

export const getSelectedOffer = (offers = [], selections = {}) => {
  let selectedOffer = {};
  const term = get(selections, 'loanTerm', DEFAULT_TERM);

  if (isArray(offers) && !isEmpty(offers)) {
    selectedOffer =
      offers.filter(
        offer => get(offer, 'financeInformation.loanTerm') === term && get(offer, 'responseCode') === 'Success'
      )[0] ||
      offers.filter(offer => get(offer, 'responseCode') === 'Success')[0] ||
      offers.filter(offer => get(offer, 'financeInformation.loanTerm') === term)[0] ||
      offers[0];
  }
  return selectedOffer;
};

export const checkCounters = offer => get(offer, 'responseCode') === OUT_OF_POLICY_CODE;

export const isEligible = vinData => {
  const displayPrice = get(vinData, 'prices.displayPrice');
  const currentDate = getCurrentDate();
  const currentYear = currentDate.getFullYear();
  const modelYear = get(vinData, 'vehicleInfo.styleInfo.year');
  const yearAge = currentYear - modelYear;
  const mileage = get(vinData, 'vehicleInfo.mileage', 0);

  return !!displayPrice && yearAge < ELIGIBLE_YEAR_AGE && mileage < ELIGIBLE_MILEAGE;
};

const isOfferResponseCodeOutofPolicy = offer => get(offer, 'responseCode') === OUT_OF_POLICY_CODE;
export const countersTriggeredAllTerms = offers => offers.every(isOfferResponseCodeOutofPolicy);
export const countersTriggeredSomeTerms = offers => offers.some(isOfferResponseCodeOutofPolicy);

export const isAvailableLocation = stateCode => !INELIGIBLE_STATE_CODES.some(state => stateCode === state);
